import React, { Component, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSiteMetadata } from "../global"

import styled from "styled-components"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import axios from 'axios';
import { navigate, useMatch } from '@reach/router';

import { AuthService, useAuth } from "gatsby-theme-auth0";

import globalHook from 'use-global-hook';

const isBrowser = typeof window !== "undefined";

const initalState = {
  error: false,
  msg: "",
  pending: false
}
const actions = {
  setError: (store, error) =>{
    console.log("Set Error");
    store.setState({error: true, msg: error});
  },
  clearError: (store) => {
    store.setState({error: false, msg: ""});
  },
  setPending: (store, value) => {
    store.setState({pending: value});
  }
}

const useGlobal = globalHook(React, initalState, actions);

//Wait for chargebee to be defined, just in case
function _wait_for_chargebee(){
  return new Promise((resolve, reject)=>{
    let interval = setInterval(()=>{
      if((typeof window.Chargebee !== 'undefined')&&(typeof window.cb_vars !== 'undefined')){
        try{
          window.Chargebee.getInstance()
          clearInterval(interval);
          resolve();
        }
        catch(err){
          console.log('no instance yet');
        }
      }
    }, 1000);
  });
}

//Used to call open the checkout programmictly. (But the auth method)
const Checkout = (plan) => {
  //console.log("Open Checkout " + plan);
  //const { app_domain } = useSiteMetadata();
  _wait_for_chargebee().then(()=>{
    open_checkout(plan, window.cb_vars.app_domain)
      .catch(err => {
        window.heap && window.heap.track('Chargebee:checkout_error',{'plan': plan, 'error':err.response.status });
        if (err.response && err.response.status !== 500){
          console.log(err.response);
          if(err.response.data.redirect && err.response.data.url){
            window.heap && window.heap.track('Chargebee:checkout_redirect',{'plan': plan, 'redirect':err.response.data.url });
            navigate(err.response.data.url);
          }
        }
      });
  });
}

const Chargebee = () => {
    const { site } = useStaticQuery(query);
    
    useEffect(()=>{
      window.cb_vars = {};
      window.cb_vars.app_domain = site.siteMetadata.app_domain;
      window.cb_vars.CBInstance = site.siteMetadata.CBInstance;
      console.log('CB Vars set');
      window.ChargeBee && window.Chargebee.registerAgain();
      
    });

    return (
      <React.Fragment>
        <Helmet>
          <script src="https://js.chargebee.com/v2/chargebee.js" data-cb-site={ site.siteMetadata.CBInstance }></script>
        </Helmet>
      </React.Fragment>
    )
}

const ErrorMessage = ({className}) =>{
  const [gState, gActions] = useGlobal();
  useEffect(()=>{
  }, [gState.error]);

  return (
        <div className={className}>
          { gState.error && <div id="wrapper"><div id="msg"><p>{gState.msg}</p><button onClick={gActions.clearError}>X</button></div></div>}
        </div>

    )
}

const Buy = ({plan, text, className}) =>{
  const { isLoading, isLoggedIn, profile } = useAuth();
  const [gState, gActions] = useGlobal();
  
  const {app_domain} = useSiteMetadata();

  //let pending = false;
  useEffect(()=>{
    console.log("pending: " + gState.pending);
  },[gState.pending]);

  const do_click = () => {
    gActions.setPending(true);
    gActions.clearError();
    //console.log("isLoading: " + isLoading);
    //console.log("isLoggedIn: " + isLoggedIn);
    //console.log("AuthService: " + AuthService.isAuthenticated());
    if(isLoading){
      // Wait
      console.log("Is Loading");
    }
    if(!AuthService.isAuthenticated()){
      //Log in
      console.log("Is Not Logged In");
      AuthService.login(plan);
    }
    else {
      console.log('opening checkout');
      //heap.track('Purchase', {dollars: 50, item: 'Furby'});
      open_checkout(plan, app_domain)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        if (err.response && err.response.status !== 500){
          console.log(err.response);
          window.heap && window.heap.track('Chargebee:checkout_error',{'error': err.response.status, 'message': err.response.data.error_msg });
          gActions.setError(err.response.data.error_msg);
          if(err.response.data.redirect && err.response.data.url){
            window.heap && window.heap.track('Chargebee:checkout_redirect',{'plan': plan, 'redirect':err.response.data.url });
            navigate(err.response.data.url);
          }
        }
        else{
          gActions.setError("An Error has occurred");
        }
        gActions.setPending(false);
      });
    }
    gActions.setPending(false);
    //setTimeout(()=>{console.log('selpt');}, 5000);
  }

  //return  (<div><button disabled={waiting} className={className} onClick={ do_click } > { text } </button><p>{ waiting }</p></div>)
  return  (<div><button disabled={gState.pending} className={className} onClick={ do_click } > { text } </button></div>)
}

function open_checkout(plan, app_domain){
    //console.log(window.Chargebee);
    //const { app_domain } = useSiteMetadata()
    let chargebeeInstance = window.Chargebee.getInstance();
    const url = `https://${ app_domain }/register/?plan=${plan}`
    //console.log(AuthService.getIdToken());
    window.heap && window.heap.track('Chargebee:checkout',{'plan': plan });

    const config = {
        headers: { Authorization: `Bearer ${AuthService.getIdToken()}` }
    };
    
    let redirect = null;
    function _open(page){
      let success = false;
      return new Promise((_resolve, _reject) =>{
        window.heap && window.heap.track('Chargebee:checkout_opened',{'plan': plan });
        window.gtag && window.gtag('event', 'conversion', {'send_to': 'AW-667964474/syNDCKuKnt0BELqowb4C'});

        chargebeeInstance.openCheckout({
          hostedPage: () =>{return Promise.resolve(page)}, //load the page
          success: (id) =>{
            success=true;
            window.heap && window.heap.track('Chargebee:checkout_success',{'plan': plan, 'id': id });
            _resolve(id); 
            },  //If transaction complete
          error: (error) => {_reject(error);},            //If an error occurs
          step: function(step) {
            window.heap && window.heap.track('Chargebee:checkout_step',{'step': step });
          },
          close: ()=>{
            window.heap && window.heap.track('Chargebee:checkout_closed',{'plan': plan });

            if(success){  //Make sure the transaction was completed before redirecting
              navigate(redirect);
            }
          }
        });
      });
    }

    return axios.get(url, config)
        .then(res =>{
          redirect = res.data.redirect;
          return _open(res.data.page);});
}

const query = graphql`
  query CBInstance {
    site {
      siteMetadata {
        CBInstance,
        app_domain
      }
    }
  }`

export { Chargebee, Buy, Checkout, ErrorMessage }