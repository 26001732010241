/*
* Overridden service.
* Changes
*   public login()
      added parameter to function
      if paramter is string, it will save that string in storage as postLoginCheckout 

    public handleAuthentication()
      If postLoginCheckout is present in storage after checkout, it will call a function using the value.
      This will open a ChargeBee checkout using the value as the plan.
      
*/
import * as auth0 from "auth0-js";
import { navigate } from "gatsby";
import { config } from "./config";

import { Checkout } from "../../components/common/chargebee"

const isBrowser = typeof window !== "undefined";

export interface SessionState {
  isLoggedIn: boolean;
  userProfile?: auth0.Auth0UserProfile;
  accessToken?: string;
  idToken?: string;
}

class Auth {
  private accessToken?: string;
  private idToken?: string;
  private userProfile?: auth0.Auth0UserProfile;

  private busy?: boolean;

  public sessionStateCallback = (_state: SessionState) => {};

  private auth0 = process.env.AUTH0_DOMAIN
    ? new auth0.WebAuth(config)
    : undefined;

  public login = (plan=null) => {
    if (!isBrowser) return;
    //console.log("logging in");
    // Save postLoginUrl so we can redirect user back to where they left off after login screen
    localStorage.setItem("postLoginUrl", window.location.pathname);
    //localStorage.setItem("postLoginUrl", "/plan");

    if(typeof plan === 'string'){
      localStorage.setItem("postLoginCheckout", plan);
    }

    this.auth0 && this.auth0.authorize();
  };

  public handleAuthentication = () =>
    new Promise((resolve, reject) => {
      this.auth0 &&
        this.auth0.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            console.log(authResult.idTokenPayload.sub);

            const postLoginUrl = localStorage.getItem("postLoginUrl");
            const postLoginCheckout = localStorage.getItem("postLoginCheckout");
            localStorage.removeItem("postLoginUrl");
            localStorage.removeItem("postLoginCheckout");
            
            window.heap && authResult.idTokenPayload && window.heap.identify(authResult.idTokenPayload.sub);

            if (postLoginUrl) {
              navigate(postLoginUrl);

              if (postLoginCheckout){
                Checkout(postLoginCheckout);
              }
            }

            return resolve(authResult);
          } else if (err) {
            console.log(err);
            return reject(err);
          }
          return resolve();
        });
    });

  public getAccessToken = () => this.accessToken;

  public getIdToken = () => this.idToken;

  public getUserProfile = () => this.userProfile;

  private setSession(authResult: auth0.Auth0DecodedHash) {
    if (!isBrowser) return;
    localStorage.setItem("isLoggedIn", "true");
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.userProfile = authResult.idTokenPayload;
    this.sessionStateCallback({
      isLoggedIn: true,
      userProfile: this.userProfile,
      accessToken: this.accessToken,
      idToken: this.idToken,
    });
  }

  public checkSessionOnline = () =>
    new Promise(resolve => {
        this.auth0.checkSession({}, (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            return resolve(authResult);
          }
          if (err && err.error === "login_required") {
            // User has been logged out from Auth0 server.
            // Remove local session.
            this.localLogout();
          }
          return resolve(authResult);
        });
    });

  public checkSession = () => 
    new Promise(resolve => {
      if (this.busy){
        console.log("Busy");
        return resolve();
      }

      if(!this.userProfile || Date.now() > this.userProfile.exp * 1000){
        console.log("Check Session Online");
        this.busy = true;
        return this.checkSessionOnline().then((authResult)=>{
          this.busy = false; 
          //console.log("Session checked: " + this.accessToken);
          //console.log("Session Token: " + this.idToken);
          //console.log("profile: " + this.userProfile);
        });
      }
      else{
        console.log("What ever.");
        resolve();
      }
    });

  public localLogout = () => {
    if (!isBrowser) return;
    // Remove tokens and user profile
    this.accessToken = undefined;
    this.idToken = undefined;
    this.userProfile = undefined;

    localStorage.removeItem("isLoggedIn");
    this.sessionStateCallback({
      isLoggedIn: false,
      userProfile: undefined,
      accessToken: undefined,
      idToken: undefined,
    });
  };

  public logout = () => {
    if (!isBrowser) return;
    this.localLogout();
    this.auth0 &&
      this.auth0.logout({
        returnTo: window.location.origin,
      });
  };

  public isAuthenticated = () => {
    if (!isBrowser) return false;
    return localStorage.getItem("isLoggedIn") === "true";
  };
}

const auth = new Auth();

export default auth;